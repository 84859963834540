import styled from "styled-components";
import { MAXW1440IDGET, MAXW1920IDGET, MAXW2560IDGET, MaxWidth } from "../../base/style";
import jeff from "../../assets/user/jeff.jpg"
import Will from "../../assets/user/Will.jpg"
import roy from "../../assets/user/roy.jpg"
import finn from "../../assets/user/Finn Li.png"
import Dawnzhu from "../../assets/user/Dawn zhu.jpg"
import chris from "../../assets/user/chris.png"
import Leafan from "../../assets/user/Leafan.jpg"
import ZGF from "../../assets/user/ZGF.jpg"
import Aimee from "../../assets/user/Aimee.png"
import ALEX from "../../assets/user/ALEX.jpg"
import twitter from "../../assets/imgs/twitter_icon.png"
import github from "../../assets/imgs/github_icon.png"
import linkedin from "../../assets/imgs/linkedin.png"
import React from "react";


export function HelpWidget() {
  return <HelpStyle>
    <h3 className="title">
      Who We Are
    </h3>
    <div className={'wap'}>
      <CellItemWidget
        img={jeff}
        name={"Jeff"}
        subName={'Founding Partner'}
        linkedin={'https://www.linkedin.com/in/jeff-p-r/'}
      />

      <CellItemWidget
        img={Will}
        name={"Will"}
        subName={'Founding Partner'}
        linkedin={'https://www.linkedin.com/in/wkurniaw'}
        twitter={'https://twitter.com/thefutureaidos'}
      />

      <CellItemWidget
        img={roy}
        name={"Roy Zhang"}
        subName={'Founding Partner'}
        linkedin={'https://www.linkedin.cn/in/roy-zhang-09b1b2145'}
        twitter={'https://twitter.com/roybitsir'}

      />
      <CellItemWidget
        img={finn}
        name={"Finn Li"}
        twitter={'https://twitter.com/finn_web3'}
        subName={'Partner'}

      />
      <CellItemWidget
        img={Dawnzhu}
        name={"Dawn Zhu"}
        twitter={'https://twitter.com/nullsirzh'}
        subName={'Researcher'}

      />

      <CellItemWidget
        img={chris}
        name={"Chris Lin"}
        github={'https://github.com/HAOYUatHZ'}
        subName={'Researcher Partner'}
      />

      {/*<CellItemWidget*/}
      {/*    img={Img1}*/}
      {/*    name={"Eric"}*/}
      {/*    subName={'Venture Partner'}*/}

      {/*/>*/}
      <CellItemWidget
        img={Leafan}
        name={"Leafan Chan"}
        github={'https://github.com/leafan'}
        subName={'Researcher'}

      />
      <CellItemWidget
        img={ZGF}
        github={'https://github.com/zgfzgf'}
        name={"Z G F"}
        subName={'Researcher'}
      />
      <CellItemWidget
        img={Aimee}
        name={"Aimee"}
        subName={'Communication Manager Asia'}
      />
      {/* <CellItemWidget
        img={ALEX}
        name={"Alex"}
        linkedin={'https://www.linkedin.com/in/alejandro-fern%C3%A1ndez-tenorio-6a0a84216/'}
        subName={'Investment Associate'}
      /> */}
      <div />
    </div>


  </HelpStyle>
}

type CellItemType = {
  img: string,
  name: string,
  subName: string,
  github?: string,
  twitter?: string,
  linkedin?: string

}

export function CellItemWidget(props: CellItemType) {

  return (
    <CellItemStyle>
      <div className="box">
        <div className="left">
          <img src={props.img} alt="" />
        </div>
        <div className="right">
          <h3 className={'sub_name'}>{props.name}</h3>
          <h4>{props.subName}</h4>
        </div>
        <div className="bottom">
          {
            props.twitter && <a href={props.twitter} target={"_blank"}><img src={twitter} alt="" /></a>
          }
          {
            props.linkedin && <a href={props.linkedin} target={"_blank"}><img src={linkedin} alt="" /></a>
          }
          {
            props.github && <a href={props.github} target={"_blank"}><img src={github} alt="" /></a>
          }
        </div>
      </div>
    </CellItemStyle>
  );
}

const CellItemStyle = styled.div`

  z-index: 100;
  padding-bottom: 100px;
  position: relative;


  .box {
    background: linear-gradient(269.15deg, rgba(0, 0, 0, 0.08) 0.65%, rgba(255, 255, 255, 0.0008) 99.19%), rgba(255, 255, 255, 0.8);
    box-shadow: 4px -4px 1px rgba(0, 0, 0, 0.1), -4px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 45px 30px 15px 30px;
    box-sizing: border-box;
    margin-bottom: 30px;
    align-items: inherit;
    transition: all .3s;
    height: 100%;

    &:hover {

      transform: scale(1.1);
    }
  }

  .right {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    text-align: center;

    h3 {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 44px;
      margin-bottom: 20px;
      color: rgba(32, 32, 32, 1);
    }

    h4 {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      box-sizing: border-box;
      color: rgba(128, 128, 128, 1);
    }

  }

  .left {
    display: block;
    border: 4px solid #E1E1E1;
    border-radius: 20px;
    width: 200px;
    height: 200px;
    margin: 0 auto 15px auto;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }
  
  .bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    
    a{
      display: block;
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
    img{
      display: block;
      width: 48px;
      height: 48px;
      
    }
  }

  @media (min-width: ${MAXW1920IDGET}px) {


    .right {
      padding-left: 0;

    }
  }


  @media (max-width: ${MAXW2560IDGET}px) {
    .left {


    }

  }

  @media (max-width: ${MAXW1920IDGET}px) {
    .left {


    }


  }


  @media (max-width: ${MAXW1440IDGET}px) {

    .right {
      h3 {
        font-size: 28px;
        margin-bottom: 10px;
        line-height: 38px;
      }

      h4 {
        font-size: 20px;
      }
    }
  }


`

const HelpStyle = styled(MaxWidth)`
  padding-bottom: 0;

  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  width: 90%;
  padding-top: 100px;

  .title {
    background: linear-gradient(269.15deg, rgba(0, 0, 0, 0.08) 0.65%, rgba(255, 255, 255, 0.0008) 99.19%), rgba(255, 255, 255, 0.8);
    box-shadow: 4px -4px 1px rgba(0, 0, 0, 0.1), -4px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    display: inline-block;
    padding: 12px 120px;
    box-sizing: border-box;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 51px;
    text-align: center;
    color: #000000;
    text-shadow: -2px 2px 1px rgba(118, 118, 118, 0.5);
    margin-bottom: 60px;
    z-index: 100;
    position: relative;
  }


  .wap {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    > div {
      width: 26%;
    }
  }

  @media (max-width: ${MAXW1440IDGET}px) {
    .wap {
      > div {
        width: 45%;
      }
    }
  }

  @media (max-width: 600px) {
    .wap {
      justify-content: center;

      > div {
        width: 80%;
      }
    }
  }



`