import styled from "styled-components";
import AboutBgPngImg from "../../../assets/bg/about.jpg";
import {MAXW1920IDGET, MAXW2560IDGET} from "../../../base/style";

export const ScrollStyle = styled.div`
  height: 100vh;
  position: relative;


  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 120vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("${AboutBgPngImg}");

    position: relative;

    //mask: linear-gradient(0deg, transparent 10px, #fff 75px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: -1;
    //display: none;
  }

  .bg_3 {


    display: block;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: -1;

    img {
      display: block;
      width: 100%;
    }
  }


  .right_bottom {

    max-width: ${MAXW1920IDGET}px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: calc(10px + 0vh);

    p {
      padding: 8px 5%;
      text-align: left;
      font-family: 'VictorMono-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 41px;
      display: flex;
      align-items: center;
      color: #FFFFFF;

      text-shadow: 0px 0px 8px #000000;
    }

    .scroll {
      padding-top: 100px;
      text-align: center;
      cursor: pointer;

      span {
        font-family: 'VictorMono-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-shadow: 0px 0px 8px #000000;
        color: #FFFFFF;
        line-height: 1;
      }

      img {
        display: block;
        width: 20px;
        height: 9px;
        margin: 0 auto;
        margin-top: 3px;

      }
    }
  }

  @media (min-width: ${MAXW1920IDGET}px) {
    .right_bottom {
      max-width: 9999px;
      padding-left: 48px;
      padding-top: 150px;

      bottom: calc(10px + 0vh);

      p {
        width: 1400px;
        line-height: 1.4;
        font-size: 36px;
        padding: 0;
      }
      .scroll{
        padding-top: 150px;
        span {
          font-size: 18px;
          line-height: 25px;
        }
      }
      
    }
  }





  @media (max-width: 900px) {
    .right_bottom {
      width: 90%;

      p {
        width: 80%;
      }

      .scroll {
        padding-top: 50px;
      }
    }
  }

`