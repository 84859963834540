import * as React from "react";
import {ChildrenProps} from "../../type/children_props";
import styled from "styled-components";
import HomeBgImg from "../../assets/bg/home.png"
import {BottomWidget} from "../../widgets/bottom";
import {MAXW1440IDGET, MAXW1920IDGET} from "../../base/style";
import ScrollImg from "../../assets/bg/scroll_icon.png";
import {useHistory} from "react-router-dom";

export function HomePage({children}: ChildrenProps) {
    let {push} = useHistory();

    const handleScroll = (e: any) => {
        if (e.nativeEvent.deltaY >= 30) {
            // push('/about?a', {"a": 'a'});
        }
    }
    return (
        <HomeStyle onWheel={handleScroll}>
            {children}

            <h3 className="right_bottom">
                <p>Caballeros Capital is an early-stage venture firm focused on investing in innovative and
                    founder-driven Web3 protocols and companies .</p>
                <div className="scroll" onClick={() => push('/about?a', {"a": 'a'})}>
                    <span>About</span>
                    <img src={ScrollImg} alt=""/>

                </div>
            </h3>

            <BottomWidget/>
        </HomeStyle>
    )
}


const HomeStyle = styled.div`
  min-height: 100vh;
  background-image: url("${HomeBgImg}");
  background-position: top center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;

  .right_bottom {

    max-width: ${MAXW1920IDGET}px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-left: 48px;
    bottom: 80px;


    p {
      padding: 0;
      padding-bottom: 100px;
      box-sizing: border-box;
      
      width: 1386px;
      text-align: left;
      font-family: 'VictorMono-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 51px;
      display: flex;
      align-items: center;
      color: #000000;
      text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
    }

    .scroll {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      text-align: center;
      cursor: pointer;
      padding-top: 100px;

      span {
        font-family: 'VictorMono-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-shadow: 0px 0px 8px #000000;
        color: #FFFFFF;
        line-height: 1;
      }

      img {
        display: block;
        width: 20px;
        height: 9px;
        margin: 0 auto;
        margin-top: 3px;

      }
    }
  }

  @media (min-width: ${MAXW1920IDGET}px) {
    .right_bottom {
      max-width: 9999px;
      padding-left: 48px;
      bottom: 90px;

      p {
        width: 1386px;
        line-height: 1.4;
        font-size: 36px;
        padding: 0;
        padding-bottom: 150px;

      }

      .scroll {

        span {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
  @media (max-width: ${MAXW1440IDGET}px) {
    .right_bottom {
      max-width: 9999px;
      padding-left: 70px;
      bottom: 90px;

      p {
        width: 1100px;
        line-height: 1.4;
        font-size: 28px;
        padding: 0;
        padding-bottom: 50px;

      }

      .scroll {
        //padding-top: 50px;
      }

    }
  }


  @media (max-width: 900px) {
    .right_bottom {
      width: 90%;
      bottom: 10%;

      .scroll {
        //padding-top: 50px;
      }

      p {
        width: 80%;
      }
    }
  }
`