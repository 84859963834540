import React, {useCallback, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import LogoSvg from "../assets/bg/old_img.png"

import {NavLink} from "react-router-dom";
import {MAXW1920IDGET} from "../base/style";
import {scroller} from "react-scroll/modules";

export const Headner: React.FC<{ opacity: number, ac: number }> = ({opacity, ac}) => {
    const [hash, setHash] = useState("");
    useEffect(() => {
        setHash(window.location.hash);
    }, [])

    const goToFn = useCallback((ev: string) => {
        scroller.scrollTo(ev, {
            duration: 500,
            delay: 10,
            smooth: 'easeInOutQuint',
        })
    }, [])

    return (
        <WapStyle>
            <div className="innder">
                <div className="logo"  onClick={() => goToFn('hander_01')}>
                    <img src={LogoSvg} alt=""/>
                </div>
                <div onClick={() => {
                    setHash(window.location.hash);
                }} className={`right ${hash === '#/portfolio' ? '' : ""}`}>
                    <StyledLink to={'/home'}>Home</StyledLink>
                    <StyledLink to={'/about'}>About</StyledLink>
                    <StyledLink to={'/portfolio'}>Portfolio</StyledLink>
                    <StyledLink to={'/team'}>Team</StyledLink>
                    <HrefP href="mailto:contact@caballeros.capital">Contact</HrefP>
                </div>
            </div>

        </WapStyle>
    );
}
const StyledLink = styled(NavLink)`
  ${() => Herf}
`;

const HrefP = styled.a`
  text-decoration: none;
  ${() => Herf}


`

const Herf = css`
  margin-left: 32px;
  cursor: pointer;
  text-decoration: none;

  padding: 5px 0;
  transition: all 1s;
  font-size: 18px;
  color: #5D6785;
  font-weight: 700;
  font-family: 'Arial';


  &:hover {

  }

  &.active {

    color: #FFFFFF;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);

  }




`

const WapStyle = styled.div`
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100vw;

  .innder {
    margin: 0 auto;
    transition: all 1s;
    padding: 10px 2%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 110px;

      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 100%;

      }
    }

    .right {
      color: #E0E0E0;
      font-size: 16px;
      display: flex;

      p, a {
        margin-left: 32px;
        cursor: pointer;
        padding: 5px 0;
        transition: all 1s;
        font-size: 18px;
        color: #5D6785;
        font-weight: 700;
        font-family: 'Arial';
      }

      p.active, a.active {
        color: #FFFFFF;
        //text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

      }
    }

    .dark {
      > a {
        color: #323232;
        &.active {

          color: #FFFFFF;
          text-shadow: none;

        }
      }
    }
  }

  @media (min-width: ${MAXW1920IDGET}px) {
    .innder {
      .logo {
        width: 196px;
      }
    }

    .innder .right p, .innder .right a {
      font-size: 30px;

    }
  }



  @media (max-width: 768px) {
    .innder {
      .logo {
        width: 80px;
      }
    }
    .innder .right p, .innder .right a {
      font-size: 15px;
      margin-left: 15px;
      &:first-child{
        //margin-left: 0;
      }
    }
  }
`
