import styled from "styled-components";
import { ScrollWidget } from "./scroll";
import { ProjectWidget } from "./project";
import { BottomWidget } from "../../widgets/bottom";
import * as React from "react";
import { Element } from "react-scroll";
import ImgBCloub from "../../assets/cloud_img_b.png";
import ScrollImg from "../../assets/imgs/scroll_icon_black.png";
import { useHistory } from "react-router-dom";

let timeout:any = null;
export function PortfolioPage() {

  const ref: any = React.useRef(null);

  let { push } = useHistory();
  
//   const  debounce = (fn: Function, wait: any) =>{
//     let timeout:any = null;
//     return function() {
//         if(timeout !== null) 
//                 clearTimeout(timeout);
//         timeout = setTimeout(fn, wait);
//     }
// }


  const handleScroll = React.useCallback((e) => {
    let scrollTop = document.documentElement.scrollTop;
    let clientHeight = document.documentElement.clientHeight;
    let scrollHeight = document.documentElement.scrollHeight;


    if(scrollHeight < 2100) return;

    if (scrollHeight <= clientHeight + scrollTop + 100) {

      if(timeout !== null)  clearTimeout(timeout);

      // timeout = setTimeout(()=> push('/team?a', { "a": 'a' }), 300);
    }

  }, [ref])

  return <PortfolioStyle ref={ref} onWheel={handleScroll}>
    <Element name="hander_01" className="element" />

    <ScrollWidget />
    <Element name="Portfolio_01" className="element" />
    <ProjectWidget />
    <img src={ImgBCloub} alt="" className="img_bcloub" />
    <div className="scroll" onClick={() => push('/team?a', { "a": 'a' })}>
      <span>Team</span>
      <img src={ScrollImg} alt="" />
    </div>
    <BottomWidget />

  </PortfolioStyle>
}

const PortfolioStyle = styled.div`
  position: relative;
  padding-bottom: 180px;

  .scroll {
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;
    cursor: pointer;
    z-index: 9999;
    position: relative;

    span {
      font-family: 'VictorMono-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #000;
      line-height: 1;
    }

    img {
      display: block;
      width: 20px;
      height: 9px;
      margin: 0 auto;
      margin-top: 3px;

    }
  }

  .bottom_href {
    img {
      filter: brightness(0%) contrast(100%);
    }
  }

  .img_bcloub{
    position: absolute;
    display: block;
    height: 300px;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  .img_tcloub{
    position: absolute;
    display: block;
    height: 200px;
    right: 0;
    top: 110vh;
    z-index: -1;
  }
`