import styled from "styled-components";
import PortfolioBgImg from "../../assets/bg/portfolio.jpg";
import * as React from "react";
import ScrollImg from "../../assets/bg/scroll_icon.png"
import {useCallback} from "react";
import {scroller} from "react-scroll/modules";
import {MAXW1920IDGET} from "../../base/style";

export function ScrollWidget() {
    const goToFn = useCallback((ev: string) => {
        scroller.scrollTo(ev, {
            duration: 500,
            delay: 100,
            smooth: 'easeInOutQuint',
        })
    }, [])
    return <ScrollStyle>
        <h3 className="right_bottom">

            <div className="scroll"  onClick={() => goToFn('Portfolio_01')}>
                <span>SCROLL</span>
                <img src={ScrollImg} alt=""/>
            </div>
        </h3>
        <div className="bg_3">
            <img src={PortfolioBgImg} alt=""/>
        </div>
        {/*<div className="bg_2"></div>*/}
    </ScrollStyle>
}


const ScrollStyle = styled.div`
  height: 100vh;
  position: relative;

    
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 130vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("${PortfolioBgImg}");
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: -1;
  }
  .bg_3 {
    display: block;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    
    img {
      display: block;
      width: 100%;
      height: calc(100vw * 0.79);
    }
  }


  .right_bottom {
    opacity: 0;

    max-width: 1400px;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: calc(80px + 0vh);

    p {
      padding: 8px 5%;
      text-align: left;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 41px;
      display: flex;
      align-items: center;
      color: #FFFFFF;

      text-shadow: 0px 0px 8px #000000;
    }


    .scroll {
      padding-top: 100px;
      text-align: center;
      cursor: pointer;

      span {
        font-family: 'VictorMono-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-shadow: 0px 0px 8px #000000;
        color: #FFFFFF;
        line-height: 1;
      }

      img {
        display: block;
        width: 20px;
        height: 9px;
        margin: 0 auto;
        margin-top: 3px;

      }
    }
  }

  @media (min-width: ${MAXW1920IDGET}px) {
    .right_bottom {
      max-width: 9999px;
      padding: 0 3%;
      bottom: calc(10px + 0vh);
      .scroll {
        span {
          font-size: 18px;
          line-height: 25px;
        }
      }
      p {
        width: 1600px;
        line-height: 1.4;
        font-size: 36px;
      }
    }
  }

`