import styled from "styled-components";
import { MAXW1440IDGET, MAXW1920IDGET, MAXW2560IDGET } from "../../../base/style";

export const WapStyle = styled.div`
  box-sizing: border-box;
  padding: 25px 10% 0 10%;

  .bar {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    width: 96%;
    margin: 0 auto;
    padding-bottom: 40px;

    .mini{
      display: none;
    }

  }

  .title {
    font-weight: 400;
    color: #E0E0E0;
    font-size: 24px;
    margin-bottom: 47px;
  }

  color: rgba(0, 0, 0, .4);


  @media (min-width: ${MAXW1920IDGET}px) {
    padding: 15vh 0 0 0;
    .bar {
      max-width: 100%;
      width: 1689px;
      padding-bottom: 25px;
      box-sizing: border-box;

      > div {
        justify-content: center;
      }
    }

    .production {
      width: 1500px;
      margin: 0 auto;
    }
  }

  @media (min-width: ${MAXW2560IDGET}px) {
    padding: 25vh 0 0 0;
    .bar {
      max-width: 100%;
      width: 1689px;
      padding-bottom: 25px;
      box-sizing: border-box;

      > div {
        justify-content: center;
      }
    }

    .production {
      width: 1500px;
      margin: 0 auto;
    }
  }
  
  @media(max-width: ${MAXW1440IDGET}px){
    max-width: 86%;
    margin: 0 auto;
    padding: 25vh 0 0 0;
  }

  @media(max-width: ${MAXW1440IDGET}px) and (min-height: 800px){
    padding-top: 5vh;
  }


  @media (max-width: 900px) {
    .bar {
      display: flex;
      justify-content: space-between;
      max-width: 96%;
      margin: 0 auto;
      padding-bottom: 40px;
      .mini{
        display: block;
      }
      .bg{
        display: none;
      }
    }

  }

  @media (max-width: 600px) {
    padding: 62px 0% 0 0%;
    max-width: 96%;

    .bar {
      /* display: none; */
    }
  }

`


export const ItemStyle = styled.div<{ itemData: ItemData, index: number, bgImg: any }>`
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  display: inline-block;
  border-radius: 150px 150px 20px 20px;
  overflow: hidden;

  .top {
    position: relative;

    .bar_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: none;


      img {
        display: block;
        width: 100%;
      }
    }


  }


  &:hover {
  
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25), 2px -2px 4px rgba(0, 0, 0, 0.12);

    .top {
      background: ${({ itemData }) => itemData.bgColor};

      .bar_img {
        display: block;
      }

      .img {
        background-image: url("${({ bgImg }) => bgImg}") !important;
      }

      p {
        opacity: 1;
      }
    }
  }

  .bg {
    background-image: url("${({ bgImg }) => bgImg}") !important;
    height: 0px;
    width: 0;
  }


  //box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25), 2px -2px 4px rgba(0, 0, 0, 0.12);

  .top {
    width: 240px;
    height: 320px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 80px;
    box-sizing: border-box;

    .img {
      display: block;
      width: ${({ itemData }) => itemData.width}px;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100px;
    }

    p {
      opacity: 0;
      font-family: 'VictorMono-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
    }
  }

  @media (min-width: ${MAXW1920IDGET}px) {
    margin-bottom: 88px;
    .top {
      width: 320px;
      height: 420px;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding-bottom: 110px;
      box-sizing: border-box;
      position: relative;
      .img {
      
        width: ${({ itemData }) => itemData.width}px;
        top: 0;
        position: absolute;
        bottom: 0;
        height: 100%;
      }
    }
  }

  @media (max-width: ${MAXW1440IDGET}px) {
    //margin-bottom: 88px;
    .top {
      width: 280px;
      height: 380px;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding-bottom: 110px;
      box-sizing: border-box;
      position: relative;
      .img {

        width: ${({ itemData }) => itemData.width * 0.8}px;
        top: 0;
        position: absolute;
        bottom: 0;
        height: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25), 2px -2px 4px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;


    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25), 2px -2px 4px rgba(0, 0, 0, 0.12);


    .top {
      width: 160px;
      height: 220px;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding-bottom: 30px;
      box-sizing: border-box;
      position: relative;
      background: ${({ itemData }) => itemData.bgColor};


      .bar_img{
        display: block;
      }
      .img {
        background-image: url("${({ bgImg }) => bgImg}") !important;

        width: ${({ itemData }) => itemData.width * 0.6}px;
        top: 0;
        position: absolute;
        bottom: 0;
        height: 100%;
      }

      p {
        font-size: 18px;
        opacity: 1;
      }
    }
  }



`


export interface ItemData {
  text: string,
  url: string,
  bgColor: string,
  type: ProjectType,
  isEquity?: boolean,
  width: number,
  isHide?: boolean
}

export enum ProjectType {
  nil,
  infra,
  defi,
  gamefi,
  nftfi,
  Devtools,
  other,
}
