import styled from "styled-components";
import MixinImg from "../../../assets/bg/2.png";
import {MAXW1920IDGET, MAXW2560IDGET} from "../../../base/style";
import {padding_2560_306} from "../base_data";

export const AboutStyle = styled.div`
  position: relative;

  .about_max_2560 {
    display: none;
  }

  .bg {
    position: relative;
    .scroll {
      padding-top: 20px;
      padding-bottom: 130px;
      text-align: center;
      cursor: pointer;
      z-index: 9999;
      position: relative;

      span {
        font-family: 'VictorMono-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-shadow: 0px 0px 8px #000000;
        color: #FFFFFF;
        line-height: 1;
      }

      img {
        display: block;
        width: 20px;
        height: 9px;
        margin: 0 auto;
        margin-top: 3px;

      }
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url("${MixinImg}");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }



 


  @media (min-width: ${MAXW1920IDGET}px) {
    .about_max_2560 {
      display: none;
    }
    
   

    .bg {
      position: relative;
      .bg2560{
        display: none;
        .about_02{
          img{
            display: block;
            height: 40%;
          }
        }
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url("${MixinImg}");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top center;
      }
    }
  }

  @media (max-width: ${MAXW2560IDGET}px) {
    .bg {
      position: relative;
      .bg2560{
        bottom: 0;
        left: 0;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        height: 100%;
          //height: calc(100% - ${padding_2560_306}px);
        .about_01{
          height: 42%;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -1;
          img{
            width: 100%;
            //height: 100%;
            margin: 0 auto;
          }
        }

        .about_02{
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 84%;
          z-index: -2;
          img{
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
        }
        img{
          display: block;
        }

      }

      &:after {
        content: '';
        display: none;
      }
    }
  }


  @media (max-width: 900px) {
    .bg {
      &:after {
        z-index: -1;
      }
    }
  }

`