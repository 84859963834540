import * as React from "react";

import {HelpWidget} from "./help";
import {BottomWidget} from "../../widgets/bottom";
import {AboutStyle} from "./style/about_bg";
import About02 from "../../assets/bg/2560/about_02.png";
import About01 from "../../assets/bg/2560/about_01.png";
import {useRef} from "react";


export default function TeamPage() {
    const ref: any = useRef(null);


    return (
        <AboutStyle ref={ref} >

            <div className="bg">

                <HelpWidget/>


                <div style={{"height": "80px"}}></div>
                <div className="bg2560">
                    <div className="about_01">
                        <img src={About01} alt=""/>
                    </div>
                    <div className="about_02">
                        <img src={About02} alt=""/>
                    </div>
                </div>
                <BottomWidget/>

            </div>


        </AboutStyle>
    )
}

