import React from "react";
import styled from "styled-components";
import TwitterImg from "../assets/bg/x.png"
import DiscordImg from "../assets/bg/discord.png"

export const BottomWidget: React.FC = () => {


    return (
        <WapStyle className={'bottom_href'}>
            <a target={'_blank'} href="https://twitter.com/CaballerosCap"><img src={TwitterImg} alt=""/></a>
            <div style={{width:'82px'}}></div>
            <img src={DiscordImg} alt=""/>
        </WapStyle>
    );
}


const WapStyle = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  img {
    display: block;
    width: 32px;
    cursor: pointer;

  }

`
