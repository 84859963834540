import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { Grid, Hidden, } from "@material-ui/core";
import barImg from "../../assets/project/bar_img.png"
import { NavBottomWidget } from "./widget/nav_bottom";
import { ItemData, ItemStyle, ProjectType, WapStyle } from "./widget/wap_style";
import { useHistory } from "react-router-dom";


let pcBar = [0, 1, 2, 3, 4, 5, 6]

const listData: ItemData[] = [
    {
        width: 222,
        text: `Radicle`,
        type: ProjectType.Devtools,
        url: "https://radicle.xyz/",
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Ren`,
        width: 100,

        type: ProjectType.defi,
        url: "https://renproject.io/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 56, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Nervos`,
        width: 100,
        type: ProjectType.infra,
        url: "https://www.nervos.org/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Anima`,
        width: 259,
        type: ProjectType.nftfi,
        url: "https://anima.supply/",
        bgColor: "linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `HOPR`,
        width: 254,

        type: ProjectType.infra,
        url: "https://hoprnet.org/",
        bgColor: "linear-gradient(209.05deg, rgba(219, 0, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Pudgy Penguins`,
        type: ProjectType.nftfi,
        width: 100,

        url: "https://pudgypenguins.com/",
        bgColor: "linear-gradient(209.05deg,#FFB48D 1.82%,rgba(0,0,0,0.01) 97.32%);",
        isEquity: true,

    },
    {
        text: `PLAY3`,
        width: 100,

        type: ProjectType.gamefi,
        url: "https://app.play3.gg",
        bgColor: "linear-gradient(209.05deg, rgba(0, 56, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `nftperp`,
        width: 100,
        type: ProjectType.nftfi,
        url: "https://nftperp.xyz/",
        bgColor: "linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `ZKX`,
        width: 280,
        isHide: true,
        type: ProjectType.defi,
        url: "https://zkx.fi/",
        bgColor: "linear-gradient(209.05deg, rgba(20, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `pebble`,
        width: 100,
        type: ProjectType.Devtools,
        url: "https://www.pebble.us/",
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Polynomial`,
        width: 120,

        type: ProjectType.defi,
        url: "https://polynomial.fi/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 56, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Hackmd`,
        type: ProjectType.Devtools,
        width: 100,
        url: "https://hackmd.io/",
        bgColor: "linear-gradient(209.05deg, rgba(219, 0, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Itos`,
        width: 120,
        type: ProjectType.defi,
        url: "https://www.itos.fi/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Eclipse`,
        type: ProjectType.infra,
        width: 100,
        url: "https://www.eclipse.builders/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 56, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Myso`,
        type: ProjectType.defi,
        width: 200,
        url: "https://myso.finance/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `gangbusters`,
        width: 160,
        type: ProjectType.gamefi,
        url: "https://www.gangbusters.io/",
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Flow`,
        type: ProjectType.nftfi,
        width: 240,
        isHide: true,
        url: "https://flow.so/",
        bgColor: " linear-gradient(209.05deg, rgba(219, 0, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `sentiment`,
        width: 100,
        type: ProjectType.defi,
        url: "https://www.sentiment.xyz/",
        bgColor: "linear-gradient(209.05deg, rgba(18, 20, 18, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Moonveil`,
        url: "https://www.moonveil.gg",
        type: ProjectType.gamefi,
        width: 160,
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Unlockd`,
        width: 100,
        type: ProjectType.nftfi,
        url: "https://www.unlockd.finance/",
        bgColor: "linear-gradient(209.05deg, rgba(219, 0, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Krypton`,
        url: "https://krypton.exchange/",
        type: ProjectType.defi,
        width: 160,

        bgColor: "linear-gradient(209.05deg, rgba(219, 0, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Sermorpheus`,
        url: "https://app.sermorpheus.com/",
        type: ProjectType.nftfi,
        width: 100,

        bgColor: "linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Sociocat`,
        type: ProjectType.defi,
        width: 120,

        url: "https://sociocat.xyz/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 56, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `photon`,
        width: 100,
        type: ProjectType.infra,
        url: "https://photon.storage/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Nsure`,
        type: ProjectType.defi,
        width: 100,
        isHide: true,
        url: "https://nsure.network/#/home",
        bgColor: "linear-gradient(209.05deg, rgba(20, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `material10`,
        type: ProjectType.gamefi, width: 100,

        url: "http://material10.com/",
        bgColor: "linear-gradient(209.05deg, rgba(219, 0, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `insrt`,
        url: "https://insrt.finance/",
        type: ProjectType.nftfi, width: 100,

        bgColor: "linear-gradient(209.05deg, rgba(20, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `OP Games`,
        type: ProjectType.gamefi,
        url: "https://opgames.org/",
        width: 72,

        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Vendor`,
        width: 100,
        isHide: true,

        url: "https://vendor.finance/",
        type: ProjectType.defi,
        bgColor: "linear-gradient(209.05deg, rgba(0, 56, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Rivalz`,
        width: 110,

        type: ProjectType.gamefi,
        url: "https://rivalz.ai/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `dropmints`,
        type: ProjectType.nftfi,
        width: 100,
        isHide: true,

        url: "https://dropmints.com/",
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Titan`,
        type: ProjectType.other,
        url: "https://titan.io/",
        width: 220,

        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Y2k`,
        type: ProjectType.defi,
        width: 100,

        url: "https://www.y2k.finance/",
        bgColor: "linear-gradient(209.05deg, rgba(20, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `bowled`,
        type: ProjectType.gamefi,
        width: 100,
        isHide: true,
        url: "https://www.bowled.io/",
        bgColor: " linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `ETZ`,
        type: ProjectType.other,
        width: 156,

        url: "https://etzsoft.com/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 56, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `prePO`,
        type: ProjectType.defi,
        width: 100,

        url: "https://prepo.io/",
        bgColor: "linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `深潮`,
        type: ProjectType.other,
        width: 180,

        url: "https://www.techflowpost.com/",
        bgColor: "linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },


    {
        text: `FUKU`,
        type: ProjectType.nftfi,
        width: 100,
        isHide: true,

        url: "https://www.fuku.xyz/",
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `ondefy`,
        type: ProjectType.defi,
        width: 100,

        url: "https://ondefy.com/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Aqueduct`,
        type: ProjectType.defi,
        width: 100,
        url: "https://www.aqueduct.fi/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },


    {
        text: `Range`,
        type: ProjectType.infra,
        width: 100,
        url: "https://www.range.org/",
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `interface`,
        width: 100,
        url: "https://www.interface.social/",
        type: ProjectType.Devtools,
        bgColor: "linear-gradient(209.05deg, rgba(20, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Poolshark`,
        width: 100,
        isHide: true,
        url: "https://www.poolshark.fi/",
        type: ProjectType.defi,
        bgColor: "linear-gradient(209.05deg, rgba(20, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Mobee`,
        width: 100,
        url: "https://www.mobee.io/",
        type: ProjectType.other,
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Novanet`,
        width: 100,
        url: "https://www.novanet.xyz/",
        type: ProjectType.infra,
        bgColor: "linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },
    {
        text: `Gomble`,
        width: 100,
        url: "https://gomblegames.com/",
        type: ProjectType.gamefi,
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Chomp`,
        width: 100,
        url: "https://chomp.games/",
        type: ProjectType.gamefi,
        bgColor: "linear-gradient(209.05deg, rgba(0, 255, 255, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Otim`,
        width: 100,
        url: "https://otim.xyz/",
        type: ProjectType.infra,
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Particle`,
        width: 100,
        url: "https://particle.network/",
        type: ProjectType.infra,
        bgColor: "linear-gradient(209.05deg, rgba(255, 0, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `Sofamon`,
        width: 100,
        url: "https://www.sofamon.xyz/",
        type: ProjectType.other,
        bgColor: "linear-gradient(209.05deg, rgba(250, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

    {
        text: `X-protocol`,
        width: 100,
        url: "https://xprotocol.org/",
        type: ProjectType.gamefi,
        bgColor: "linear-gradient(209.05deg, rgba(20, 255, 0, 0.1) 12.82%, rgba(0, 0, 0, 0.001) 97.32%)",
    },

];

export const ProjectWidget: React.FC = () => {


    let { push } = useHistory();


    const [ac, setAc] = useState(ProjectType.nil);
    // @ts-ignore
    let width = document.querySelector('body').offsetWidth;
    return (
        <WapStyle>
            <div className="bar">
                <div className="bg">
                    <Grid container spacing={0} alignItems={"stretch"} justify={"space-between"}>

                        {
                            pcBar.map(
                                (e, i) =>
                                    <div key={'xxxa1a' + i} onClick={() => setAc(i)}>
                                        <NavBottomWidget index={e} isAc={ac === i} />
                                    </div>
                            )
                        }
                    </Grid>
                </div>
                <div className="mini">
                    <Grid container spacing={9} alignItems={"stretch"} justify={"space-between"}>
                        {
                            [0, 2, 3, 4, 6, 1, 5].map(
                                (e, i) =>
                                    <Grid key={'xxxa2a' + i}>
                                        <div onClick={() => setAc(i)}>
                                            <NavBottomWidget index={e} isAc={ac === i} />
                                        </div>
                                    </Grid>
                            )
                        }
                    </Grid>
                </div>


            </div>

            <div className="production">
                <Grid container spacing={3} alignItems={"stretch"}>
                    {listData.map((ev, i) => {
                        if (ev.type !== ac && ac !== ProjectType.nil) {
                            return
                        }
                        if (ev.isHide) return;
                        return <ItemWidget width={width} index={i} key={"ssss" + i} itemData={ev} />;
                    })}
                </Grid>
            </div>
        </WapStyle>
    );
}

const ItemWidget: React.FC<{
    itemData: ItemData,
    index: number,
    width: number,
}> = ({ itemData, index, width }) => {
    return (<Grid item xs={6} lg={4} sm={4} md={4}>
        <ItemStyle
            index={index}
            itemData={itemData}
            bgImg={require('../../assets/project/img_' + index + '.png').default}
            onClick={() => {
                window.open(itemData.url, "_blank")
            }}
        >
            <div className={'top'}>
                <div className="img"
                    style={{ backgroundImage: width <= 800 ? "" : 'url(' + require(`../../assets/project_d/img_${index}.png`).default + ')' }}
                >
                </div>
                <p>{itemData.text}</p>


                {
                    itemData.isEquity && <div className="bar_img">
                        <img src={barImg} alt="" />
                    </div>
                }
            </div>
            <div className="bg"></div>
        </ItemStyle>
    </Grid>
    );
}

