import {Grid} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import {MAXW1920IDGET} from "../../../base/style";

let getTitleByType = [
    'ALL',
    'General Infrastructure',
    'DEFI',
    'GAMING',
    'NFT',
    'Devtools/Middleware',
    'OTHER',
]

let getPadding = [
    '75px',
    '101px',
    '102px',
    '131px',
    '135px',
    '118px',
    '126px',
]
type NavBottomType = {
    index: number,
    isAc: boolean
};

export function NavBottomWidget({index, isAc}: NavBottomType) {
    let cStr = getTitleByType[index];
    return <NavButtonStyle pl={getPadding[index + 1]} content={cStr}>
        <p className={isAc ? 'ac' : ''}>{getTitleByType[index]}</p>
    </NavButtonStyle>
}

const NavButtonStyle = styled.p<{ content: string, pl: string }>`
  color: rgba(0, 0, 0, .4);


  p {
    padding: 5px 25px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: 'VictorMono-Bold';
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    color: black;
    cursor: pointer;
    transition: padding 300ms;
    text-shadow: 0PX 0PX 3PX #FFFFFF;
  }

  .ac {
    background: #F4F4F4;
    color: black;
    transition: padding 0ms;
  }


  @media (min-width: ${MAXW1920IDGET}px) {

    margin-right: ${({pl}) => pl};
    p {
      font-weight: 700;
      font-family: "VictorMono-Bold";
      font-size: 26px;
      padding: 15px 0;
      border-radius: 20px;
      position: relative;
    }

    .ac {
      position: relative;
      background: transparent;

      &:after {
        position: absolute;
        content: '${({content}) => content}';
        display: block;

        top: 0;
        right: 0;
        left: -75px;
        margin: auto;
        font-size: 26px;
        width: 100%;
        padding: 15px 75px;
        background-color: white;
        border-radius: 20px;

      }
    }
  }

  @media (max-width: 900px) {
    p {
      padding: 5px 25px;
      box-sizing: border-box;
      border-radius: 20px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(0, 0, 0, .4);
      cursor: pointer;
    }

    .ac {
      background: #F4F4F4;
      color: black;
    }
  }

  @media (max-width: 573px) {
    p {
      padding: 5px 15px;
      box-sizing: border-box;
      border-radius: 20px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 25px;
    }

  }
`