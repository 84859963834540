import styled from "styled-components";
import {MAXW1440IDGET, MAXW1920IDGET, MAXW2560IDGET, MaxWidth} from "../../../base/style";
import {padding_2560_306} from "../base_data";

export const QaStyle = styled(MaxWidth)`
  margin: 0 auto;

  box-sizing: border-box;
  position: relative;
  padding: 210px 187px 0 225px;

  max-width: 100%;
  height: 1180px;
 
  .inner {
    position: relative;

  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;

    background-size: contain;

    background-repeat: no-repeat;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .qs {
    background: linear-gradient(269.15deg, rgba(0, 0, 0, 0.08) 0.65%, rgba(255, 255, 255, 0.0008) 99.19%), rgba(255, 255, 255, 0.8);


    box-shadow: 4px -4px 1px rgba(0, 0, 0, 0.1), -4px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 30px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 450px;
    z-index: 100;
    position: absolute;


    img {
      display: block;
    }

    p {

      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      text-align: left;

      color: #000000;

      text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.25), 0px 0px 8px rgba(0, 0, 0, 0.25);
    }
  }

  .q1 {
    left: 10px;
    top: 109px;

    img {
      width: 100px;
      margin-right: 35px;
    }
  }

  .q2 {
    right: 70px;
    top: 247px;

    img {
      width: 80px;
      margin-right: 51px;

    }
  }

  .q3 {
    left: 100px;
    top: 486px;

    img {
      width: 100px;
      margin-right: 66px;
    }
  }

  .q4 {
    right: 0px;

    top: 667px;

    img {
      width: 100px;
      margin-right: 41px;
    }
  }




  @media (min-width: ${MAXW1920IDGET}px) {
    padding: 210px 187px 0 225px;

    max-width: 100%;
    height: 1180px;
    .qs{
      padding: 30px 47px;

      width: 600px;
      p{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }
    }
    .q1{
      left: 0;
      img{
        margin-right: 55px;
      }
    }
    .q2{
      right: 108px;
      padding-right: 20px;
      img{
        width: 76px;
        margin-right: 51px;
      }
    }
    .q3{
      left: 103px;
    }
    .q4{
      right: 0px;
    }

  }



 
  @media (min-width: ${MAXW2560IDGET}px){
    padding: ${padding_2560_306}px 20% 0 20%;
    max-width: 100%;
    height: 1380px;
  }

  @media (max-width: ${MAXW1920IDGET}px){
    padding: 210px 187px 0 225px;
    max-width: 100%;
    height: 1180px;
  }




  @media (max-width: ${MAXW1440IDGET}px) {
    padding: 130px 100px 0 125px;
    height: 980px;

    .qs {
      padding: 20px 10px;

     
    }

    .q1{
      left: 0;
      img{
        margin-right: 45px;
      }
    }
    .q2{
      right: 40px;
      padding-right: 10px;
      img{
        width: 76px;
        margin-right: 51px;
      }
    }
    .q3{
      right: 20px;

      img {
        margin-right: 46px;
      }
    }
    .q4{
      right: 0px;
    }
     
  }




  @media (max-width: 900px){
    padding: 100px 10px 0 10px;
    box-sizing: border-box;
    height: auto;
    width: 90%;

    .qs {
      position: revert;
      width: 100%;
      margin-bottom: 30px;
      padding: 20px 10px;
      
      .img{
        display: block;
        width: 80px;
        img{
          display: block;
          width: 100%;
          margin-right: 30px !important;

        }
      }

      img {
      }

      p {
        font-size: 14px;
        line-height: 1.2;
        flex: 1;
      }
    }


    .q1 {
      left: 10px;
      top: 109px;

      img {
        width: 100px;
        margin-right: 35px;
      }
    }

    .q2 {
      right: 70px;
      top: 247px;

      img {
        width: 80px;
        margin-right: 51px;

      }
    }

    .q3 {
      left: 100px;
      top: 486px;

      img {
        width: 100px;
        margin-right: 66px;
      }
    }

    .q4 {
      right: 0px;

      top: 667px;

      img {
        width: 100px;
        margin-right: 41px;
      }
    }
  }

`