import styled from "styled-components";
import {MAXW1440IDGET, MAXW1920IDGET, MAXW2560IDGET, MaxWidth} from "../../base/style";
import Img1 from "../../assets/abaout/img_4.png"
import Img2 from "../../assets/abaout/img_5.png"
import Img3 from "../../assets/abaout/img_6.png"
import Img4 from "../../assets/abaout/img_7.png"
import Img5 from "../../assets/abaout/img_8.png"

export function HelpWidget() {
    return <HelpStyle>
        <h3 className="title">
            How We Help
        </h3>

        <ul>
            <CellItemWidget
                img={Img1}
                subName={'Strategy'}
                inner={[
                    'Mechanism Design',
                    'Token Economics',
                    'Fundraising (Round Coordination)',
                    'Go-To-Market',
                    'Partnerships'
                ]}
            />

            <CellItemWidget
                img={Img2}
                subName={'Technology'}
                inner={[
                    'Code Review',
                    'Product Review',
                    'Smart Contract',
                    'Audit Referrals',
                    'Code Development Assistance (Case-by-Case Basis)'
                ]}
            />

            <CellItemWidget
                img={Img3}
                subName={'Operations'}
                inner={[
                    'Best Practices',
                    'Legal Structure'
                ]}
            />
            {/*　　　　　　　　　　　　　*/}
            <CellItemWidget
                img={Img4}
                subName={'Marketing and Public Relations'}
                inner={[
                    'Community Management(Asia)　　',
                    'Press Release 　　　　　　　　　',
                    'AMAs　　　　　　　　　　　　　　',
                    'Video Podcast'
                ]}
            />

            <CellItemWidget
                img={Img5}
                subName={'Liquidity Providing and Network Participation'}
                inner={[
                    'Bootstrap early TVL',
                    'Coordinate early protocol partners/users',
                    'Governance　　　　　　　　　　　　　',
                ]}
            />
        </ul>
    </HelpStyle>
}

type CellItemType = {
    img: string,
    subName: string,
    inner: string[]
}

export function CellItemWidget(props: CellItemType) {
    return (
        <CellItemStyle>
            <img src={props.img} alt="" className="left"/>
            <div className="right">
                <h3 className={'sub_name'}>{props.subName}</h3>
                <div className="inner">
                    {
                        props.inner.map((ev) => <h3 key={ev}><span/>{ev}</h3>)
                    }
                </div>
            </div>
        </CellItemStyle>
    );
}

const CellItemStyle = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  padding-bottom: 100px;
  position: relative;
  background: linear-gradient(269.15deg, rgba(0, 0, 0, 0.08) 0.65%, rgba(255, 255, 255, 0.0008) 99.19%), rgba(255, 255, 255, 0.8);

  .right {
    flex: 1;
    padding-left: 40px;
    box-sizing: border-box;
    text-align: left;

    .sub_name {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 44px;
      margin-bottom: 20px;
      color: #010101;
    }

    .inner {
      display: flex;
      flex-wrap: wrap;

      h3 {
        min-width: 320px;
        height: 30px;
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 31px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #010101;

        span {
          display: block;
          width: 10px;
          height: 10px;
          background-color: black;
          transform: rotate(45deg);
          margin-right: 5px;
        }
      }

    }
  }

  .left {
    display: block;
    width: 80px;
  }

  @media (min-width: ${MAXW1920IDGET}px) {
    width: 1440px;
    height: 180px;
    .left {
      margin-right: 64px;
    }

    .right {
      padding-left: 0;

      .inner {
        h3 {
          //min-width: 404px;
          font-weight: 400;
          font-size: 22px;

          span {
            width: 15px;
            height: 15px;
            margin-right: 15px;
          }
        }
      }
    }
  }


  @media (max-width: ${MAXW2560IDGET}px) {
    .right {


      .inner {
        justify-content: start;

        h3 {
          min-width: 470px;
          font-size: 24px;

        }
      }
    }
  }

  @media (max-width: ${MAXW1920IDGET}px) {
    .right {


      .inner {

        h3 {
          height: 30px;
          font-size: 22px;
          min-width: 420px;
        }
      }
    }
  }


  @media (max-width: ${MAXW1440IDGET}px) {
    .right {
      .sub_name {
        font-size: 28px;
        margin-bottom: 10px;
        line-height: 38px;
      }

      .inner {
        //justify-content: start;

        h3 {
          font-size: 22px;
          min-width: 400px;
          //flex: auto;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding-top: 50px;
    .left {
      width: 60px;
    }

    .right {
      .sub_name {
        font-size: 24px;
        margin-bottom: 5px;
        line-height: 38px;
      }

      .inner {
        h3 {
          font-size: 16px;
          height: 24px;
          min-width: 280px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    position: relative;
    .left {
      width: 40px;
      position: absolute;
      right: 30px;
      top: 50px;
    }

    .right {
      padding-left: 0px;

      .sub_name {
        font-size: 18px;
        margin-bottom: 10px;
        line-height: 38px;
      }

      .inner {
        h3 {
          font-size: 14px;
          height: auto;
          min-width: 280px;
        }
      }
    }
  }
`

const HelpStyle = styled(MaxWidth)`
  padding-bottom: 0;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  width: 90%;
  padding-top: 100px;

  .title {
    background: linear-gradient(269.15deg, rgba(0, 0, 0, 0.08) 0.65%, rgba(255, 255, 255, 0.0008) 99.19%), rgba(255, 255, 255, 0.8);
    box-shadow: 4px -4px 1px rgba(0, 0, 0, 0.1), -4px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    display: inline-block;
    padding: 12px 120px;
    box-sizing: border-box;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 51px;
    text-align: center;
    color: #000000;
    text-shadow: -2px 2px 1px rgba(118, 118, 118, 0.5);
    margin-bottom: 60px;
    z-index: 100;
    position: relative;
  }


  ul {
    li {
      background: linear-gradient(269.15deg, rgba(0, 0, 0, 0.08) 0.65%, rgba(255, 255, 255, 0.0008) 99.19%), rgba(255, 255, 255, 0.8);
      box-shadow: 4px -4px 1px rgba(0, 0, 0, 0.1), -4px 4px 4px rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      height: 180px;
      padding: 20px 50px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }


  @media (max-width: 1500px) {

    ul {
      li {
        padding: 0 50px;
        height: 150px;
      }
    }

  }

  @media (max-width: 900px) {
    width: 90%;
    margin: 0 auto;
    .title {
      font-size: 18px;
      line-height: 31px;
    }

    ul {
      li {
        padding: 0 50px;
        height: 160px;
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 0 auto;
    padding-top: 50px;
    .title {
      font-size: 18px;
      line-height: 31px;
    }

    ul {
      li {
        padding: 15px 20px;
        height: auto;
      }
    }
  }
`