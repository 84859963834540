// routerConfig.js
import {HomePage} from "./pages/home/home";
import AboutPage from "./pages/about/about";
import {PortfolioPage} from "./pages/protfolio/protfolio";
import { matchPath } from "react-router-dom";
import TeamPage from "./pages/team/team";

export const RouterConfig = [
    {
        path: '/home',
        render: (props: any) => <HomePage {...props} />,
        meta: {
            index: 0
        }
    },
    {
        path: '/about',
        render: (props: any) => <AboutPage {...props} />,
        meta: {
            index: 1
        }
    },
    {
        path: '/a_about',
        render: (props: any) => <AboutPage {...props} />,
        meta: {
            index: 1
        }
    },
    {
        path: '/team',
        render: (props: any) => <TeamPage {...props} />,
        meta: {
            index: 1
        }
    },
    {
        path: '/portfolio',
        render: (props: any) => <PortfolioPage {...props} />,
        meta: {
            index: 2
        }
    },

]


export const getMatchRouter = (pathname: any, configs: any) =>
    configs.find((config: any) => matchPath(pathname, {
            exact: true,
            strict: false,
            ...config
        })
    )
