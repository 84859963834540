import React, {Component} from 'react';
import {CSSTransition, TransitionGroup,} from 'react-transition-group';
import {Headner} from "./widgets/headner";
import {HashRouter as Router, Route, Switch, withRouter, Redirect} from "react-router-dom";
import {RouterConfig} from "./router_config";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps: { location: any; }) {
        // @ts-ignore
        if (prevProps.location !== this.props?.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

// @ts-ignore
const ScrollWap = withRouter(ScrollToTop);


let needAnimation = true // 控制滑动自带动画冲突


const delayReset = () => { // 延后重置控制参数
    setTimeout(() => {
        needAnimation = true
    }, 16)
}
window.addEventListener('touchstart', e => {
    needAnimation = true
})
window.addEventListener('touchmove', e => {
    needAnimation = false
})
window.addEventListener('touchend', delayReset)


const getClassName = (location: any) => {
    if (location.search !== '?a') return "kong";
    return "forward";

}


let oldLocation: any = {}

function RouterPage({location, history, match}: any) {
    const classNames = getClassName(location);
    delayReset()
    oldLocation = location;
    return <ScrollWap>
        <Headner ac={0} opacity={0}/>

        <TransitionGroup
            className="router-wrapper"
            childFactory={child => React.cloneElement(
                child,
                {classNames}
            )}
        >
            <CSSTransition
                classNames="forward"
                timeout={1000}
                unmountOnExit
                mountOnEnter
                in={true}
                key={location.pathname}
            >
                <div>
                    <Switch location={location}>
                        {
                            RouterConfig.map((config, index) => (
                                <Route exact key={index} {...config}/>
                            ))
                        }

                    </Switch>
                </div>
            </CSSTransition>

        </TransitionGroup>
        <Redirect to="/home"/>

        {/*<Switch>*/}
        {/*    <Route path="/home/" component={HomePage}/>*/}
        {/*    <Route path="/portfolio/" component={PortfolioPage}/>*/}
        {/*    <Route path="/about/" component={AboutPage}/>*/}

        {/*    */}

        {/*    <Redirect to="/home"/>*/}
        {/*</Switch>*/}
    </ScrollWap>
}

function App() {

    delayReset()

    return (
        <div className="App">
            <Router>
                <Route path={'/'} component={RouterPage}></Route>

            </Router>

        </div>
    );
}

export default App;
