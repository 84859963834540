import q1Img from "../../assets/abaout/img.png"
import q2Img from "../../assets/abaout/img_1.png"
import q3Img from "../../assets/abaout/img_2.png"
import q4Img from "../../assets/abaout/img_3.png"
import * as React from "react";
import {QaStyle} from "./style/qa";

export function QaWidget() {
    return <QaStyle>
        <div className="inner">

            <div className="q1 qs">
                <div className={'img'}>
                    <img src={q1Img} alt=""/>
                </div>
                <p>
                    We partner with bold founders, sometimes at the earliest stages of their journey, and view
                    ourselves as small members of the team.
                </p>
            </div>

            <div className="q2 qs">
                <div className={'img'}>
                    <img src={q2Img} alt=""/>
                </div>

                <p>
                    We know that capital is a commodity and think critically about how to add value to founding
                    teams. As a support platform for Web3 teams, Caballeros actively helps with a myriad of
                    functions that are mission-critical for the success of a crypto-native project.
                </p>
            </div>

            <div className="q3 qs">
                <div className={'img'}>
                    <img src={q3Img} alt=""/>
                </div>
                <p>
                    We have a special emphasis on the Asian region and help teams navigate the enormous opportunity that
                    is
                    the Chinese-speaking and South-East Asian markets.
                </p>
            </div>

            <div className="q4 qs">
                <div className={'img'}>
                    <img src={q4Img} alt=""/>
                </div>
                <p>
                    Our team has “been there, done that” and has worked with or on numerous crypto projects in the past.
                    We
                    hope that our past experiences doing things the wrong way can help teams avoid obvious pitfalls.
                </p>
            </div>
        </div>
    </QaStyle>
}

//  background-image: url("${About01Img}");
