import * as React from "react";
import {ScrollWidget} from "./scroll";
import {QaWidget} from "./qa";
import {HelpWidget} from "./help";
import {BottomWidget} from "../../widgets/bottom";
import {Element} from "react-scroll";
import {AboutStyle} from "./style/about_bg";
import About02 from "../../assets/bg/2560/about_02.png";
import About01 from "../../assets/bg/2560/about_01.png";
import ScrollImg from "../../assets/bg/scroll_icon.png";
import {useHistory} from "react-router-dom";
import {useCallback, useRef} from "react";


export default function AboutPage() {
    let {push} = useHistory();
    const ref: any = useRef(null);
    const handleScroll = useCallback((e) => {
        const {pageY} = e;
        let ch = ref.current.clientHeight;
        if (pageY > ch - window.innerHeight + 100) {
            if (e.nativeEvent.deltaY >= 30) {
                // push('/portfolio?a', {"a": 'a'});
            }
        }

    }, [ref])


    return (
        <AboutStyle ref={ref} >
            <Element name="hander_01" className="element"/>

            <ScrollWidget/>
            <div className="bg">
                <Element name="about_01" className="element"/>
                <QaWidget/>
                <div  onWheel={handleScroll} >
                    <HelpWidget/>
                </div>

                <div className="scroll" onClick={() => push('/portfolio?a', {"a": 'a'})}>
                    <span>Portfolio</span>
                    <img src={ScrollImg} alt=""/>
                </div>
                <div className="bg2560">
                    <div className="about_01">
                        <img src={About01} alt=""/>
                    </div>
                    <div className="about_02">
                        <img src={About02} alt=""/>
                    </div>
                </div>
                <BottomWidget/>

            </div>


        </AboutStyle>
    )
}

