import AboutBgPngImg from "../../assets/bg/about.jpg";
import * as React from "react";
import ScrollImg from "../../assets/bg/scroll_icon.png"
import {useCallback} from "react";
import {scroller} from "react-scroll/modules";
import {ScrollStyle} from "./style/about_scroll";

export function ScrollWidget() {

    const goToFn = useCallback((ev: string) => {
        scroller.scrollTo(ev, {
            duration: 500,
            delay: 100,
            smooth: 'easeInOutQuint',
        })
    }, [])
    return <ScrollStyle>
        <h3 className="right_bottom">
            <p>Caballeros Capital is a founder-driven early-stage venture firm focused on Web 3 protocols,
                projects, and companies.</p>

            {/*<div className="scroll" onClick={() => goToFn('about_01')}>*/}
            {/*    <span>SCROLL</span>*/}
            {/*    <img src={ScrollImg} alt=""/>*/}
            {/*</div>*/}
        </h3>
        <div className="bg_3">
            <img src={AboutBgPngImg} alt=""/>
        </div>
    </ScrollStyle>
}


